import React, { useEffect, useMemo } from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"

import { GatsbyImageProps } from "gatsby-plugin-image"
import { advantages } from "../../FourAdvantagesPoints/helpers"

import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"
import { ButtonFloating } from "./ButtonFloating"

type BannerProps = {
  buttonText?: string
  title?: string
  description?: string
  orderNum?: string
  Img?: React.FC<Omit<GatsbyImageProps, "image">>
  variant?: "pkw" | "refin" | "refinMedia"
  color?: "black" | "white"
  buttonFloating?: boolean
  variantStyles?: "refinansirovanieKreditnoyKartyMedia"
}

const defaultTitle = `
  Карта «Халва»
  <br/>
  24 месяца без %
`

export default function BannerWithAdvantages({
  buttonText = "Оформить карту",
  title = defaultTitle,
  description,
  orderNum,
  Img,
  variant = "pkw",
  color = "white",
  buttonFloating = false,
  variantStyles,
}: BannerProps) {
  useEffect(() => {
    document.body.style.display = "block"
  }, [])

  const advantagesList = useMemo(
    () =>
      advantages[variant].map(
        (item, idx) =>
          item.required && (
            <div key={idx} className={styles.textItem}>
              <div className={styles.title}>{item.title}</div>
              <div className={styles.subtitle}>{item.subtitle}</div>
            </div>
          )
      ),
    [variant]
  )

  return (
    <>
      <section
        className={clsx(styles.section, styles[variant], variantStyles && styles[variantStyles])}
        data-exclude={orderNum}
      >
        <Container className={clsx(styles.container, { [styles[color]]: true })}>
          <div className={clsx(styles.secondContainer, styles[variant])}>
            {Img && <Img alt="bg image" className={clsx(styles.img, styles[variant])} />}
            <div className={clsx(styles.text, styles[variant])}>
              <div
                className={clsx(styles.head, styles[variant])}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              {description && (
                <p
                  className={clsx(
                    styles[variant],
                    styles.description,
                    variant === "refinMedia" && styles.descriptionMedia
                  )}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </div>
            <Button
              className={clsx(styles.btn, styles[variant])}
              onClick={() => handleClickBanner("click_cta")}
            >
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={clsx(styles.btnBlock, styles[variant])}>
        <Container className={styles.textBlockContainer}>
          <div className={styles.wrapper}>
            <div className={styles.textBlock}>{advantagesList}</div>
            <Button
              id="bannerCta"
              onClick={() => handleClickBanner("click_cta")}
              className={clsx(styles.mobBtn, styles[variant])}
            >
              {buttonText}
            </Button>
          </div>
        </Container>
      </div>
      {buttonFloating && (
        <ButtonFloating onClick={() => handleClickBanner("sticky")}>Оформить карту</ButtonFloating>
      )}
    </>
  )
}
