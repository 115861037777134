import React, { ChangeEvent, FocusEventHandler } from "react"

import DateField from "@ecom/ui/components/DateField"

import { FormikTouched } from "formik"
import { WrapperDataLayer } from "./WrapperDataLayer"

import * as styles from "./fields.module.scss"

type Props = {
  id: string
  name: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  error?: string
  touched?: FormikTouched<{}>
  value?: string
}

export const BirthField = ({ touched, error, handleBlur, name, ...restProps }: Props) => (
  <WrapperDataLayer touched={touched} error={touched ? error || "" : ""} name={name}>
    <div className={styles.fieldWrapper}>
      <DateField
        {...restProps}
        InputProps={{
          classes: {
            notchedOutline: styles.notchedOutline,
            root: styles.field,
          },
        }}
        onBlur={handleBlur}
        helperText={touched ? error : ""}
        error={touched && !!error}
        fullWidth
        max={new Date()}
        validAgeMin={18}
        validAgeMax={85}
        placeholder="Дата рождения"
      />
    </div>
  </WrapperDataLayer>
)
