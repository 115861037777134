// extracted by mini-css-extract-plugin
export var bannerShow = "banner-module--bannerShow--IT+TW";
export var black = "banner-module--black--Mma0p";
export var btn = "banner-module--btn--7cM6B";
export var btnBlock = "banner-module--btnBlock--uflEk";
export var btnBlockFloat = "banner-module--btnBlockFloat--8O4Eq";
export var container = "banner-module--container--bxj7f";
export var darkColor = "banner-module--darkColor--ahicT";
export var description = "banner-module--description--h5fK0";
export var descriptionMedia = "banner-module--descriptionMedia--9Ja4a";
export var fixBottom = "banner-module--fixBottom--oPndk";
export var head = "banner-module--head--Gxxwl";
export var hideMobBlock = "banner-module--hideMobBlock--zdjI8";
export var img = "banner-module--img--Pj67+";
export var mobBtn = "banner-module--mobBtn--EtJKm";
export var mobBtnFloat = "banner-module--mobBtnFloat--oEWIB";
export var red = "banner-module--red--T37j0";
export var refin = "banner-module--refin--6djlj";
export var refinMedia = "banner-module--refinMedia--TYEz1";
export var refinansirovanieKreditnoyKartyMedia = "banner-module--refinansirovanieKreditnoyKartyMedia--4Q6q8";
export var secondContainer = "banner-module--secondContainer--JT1X8";
export var section = "banner-module--section--I4zbD";
export var subtitle = "banner-module--subtitle--BS0Wz";
export var text = "banner-module--text--L-mye";
export var textBlockContainer = "banner-module--textBlockContainer--ox6Ln";
export var textItem = "banner-module--textItem--2mkUm";
export var title = "banner-module--title--z1j45";
export var wrapper = "banner-module--wrapper--aI5Q-";