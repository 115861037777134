import { FormikTouched } from "formik"

import {
  pushToDataLayerInvalidField,
  pushToDataLayerRequiredValidField,
} from "@ecom/ui/utils/pushToDataLayerUtils"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import formatToRequest from "@ecom/ui/helpers/formatToRequest"

import { events } from "../../../../helpers/events"

export const getDadata = async (
  type: string,
  data: string,
  options = {},
  url = "https://api-app.sovcombank.ru/v1/cache/dadata"
) => {
  const query = {
    query: data,
    ...options,
  }

  try {
    const res = await fetch(`${url}/${type}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(query),
    })

    if (!res.ok) {
      throw new Error(`Could not fetch, status: ${res.status}`)
    }

    pushToDataLayer(events.dadata.success)

    return await res.json()
  } catch (err) {
    pushToDataLayer(events.dadata.error)

    return console.error("Dadata error", err)
  }
}

export const checkDataLayerField = (
  touched: FormikTouched<{}> | undefined,
  error: string | undefined,
  name: string
) => {
  if (touched && !error) {
    pushToDataLayerRequiredValidField(name)
  } else if (touched && error) {
    pushToDataLayerInvalidField(name, error)
  }
}

export const formatBirthDate = (value: string | undefined) =>
  value ? formatToRequest({ birthDate: value }, { birthDate: "date" }).birthDate : undefined
