import React, { ChangeEvent, FocusEventHandler, useEffect, useState } from "react"
import { FormikTouched } from "formik"

import TextField from "@material-ui/core/TextField"
import Autocomplete from "@ecom/ui/components/AutocompleteField"
import CircularProgress from "@material-ui/core/CircularProgress"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import { WrapperDataLayer } from "./WrapperDataLayer"

import * as styles from "./fields.module.scss"

export type REGION =
  | "Новосибирская область"
  | "Красноярский край"
  | "Пермский край"
  | "Кемеровская область"
  | "Республика Башкортостан"
  | "Санкт-Петербург и Ленинградская область"

export type City = {
  value: string
  label: string
  region: REGION
}

type Props = {
  cities: City[]
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  touched?: FormikTouched<{}>
  error?: string
  id: string
  name: string
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

export const CityField = ({
  cities,
  setFieldValue,
  touched,
  error,
  id,
  name,
  handleBlur,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [isFocusing, setIsFocusing] = useState(false)

  const city = cities.find((c) => c.value === inputValue)

  useEffect(() => {
    let isCurrent = true

    if (inputValue && !!isCurrent) {
      setFieldValue("region", city?.region)
      setFieldValue("localityAddress", inputValue)
    }
    return () => {
      isCurrent = false
    }
  }, [city, inputValue, setFieldValue])

  const onBlur = () => {
    setIsLoading(true)
    setFieldValue("region", city?.region)
    setFieldValue("localityAddress", inputValue)
    setIsFocusing(false)
    setIsLoading(false)
  }

  return (
    <WrapperDataLayer touched={touched} error={touched ? error || "" : ""} name={name}>
      <Autocomplete
        getOptionLabel={(option: Record<string, string>) => option.value || ""}
        filterOptions={(x: string[]) => (Array.isArray(x) ? x : [])}
        freeSolo
        autoComplete
        disableClearable
        options={Array.isArray(cities) ? cities : []}
        includeInputInList
        loading={isLoading}
        inputValue={inputValue}
        onInputChange={(_: ChangeEvent<HTMLInputElement>, newInputValue: string) =>
          setInputValue(newInputValue)
        }
        onBlur={onBlur}
        loadingText="Загрузка..."
        noOptionsText="Нет вариантов"
        renderInput={(params: Record<string, any>) => (
          <TextField
            {...params}
            onBlur={handleBlur}
            inputRef={(input) => isFocusing && input && input.focus()}
            id={id}
            name={name}
            placeholder="Город"
            InputProps={{
              ...params.InputProps,
              classes: {
                notchedOutline: styles.notchedOutline,
                root: styles.field,
              },
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <ExpandMoreIcon classes={{ root: styles.expandIcon }} />
                  )}
                </>
              ),
            }}
            helperText={touched ? error : ""}
            error={touched && !!error}
          />
        )}
      />
    </WrapperDataLayer>
  )
}
