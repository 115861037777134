import React, { ReactElement } from "react"
import clsx from "clsx"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import { useObserver } from "../../../hooks/useObserver"
import scrollToPersonalForm from "../../../helpers/scrollToPersonalForm"
import * as styles from "./banner.module.scss"

interface IButtonProps {
  onClick?: () => void
  children: ReactElement | string
}

export const ButtonFloating = ({ onClick = scrollToPersonalForm, children }: IButtonProps) => {
  const isVisible = useObserver(["bannerCta", "form", "bottomCta"])

  return (
    <div className={clsx(styles.btnBlockFloat, { [styles.hideMobBlock]: isVisible })}>
      <Container>
        <Button onClick={onClick} className={styles.mobBtnFloat}>
          {children}
        </Button>
      </Container>
    </div>
  )
}
