import React, { ChangeEvent } from "react"
import Link from "@material-ui/core/Link"
import Checkbox from "@material-ui/core/Checkbox"
import FormHelperText from "@material-ui/core/FormHelperText"
import { FormikTouched } from "formik"
import { WrapperDataLayer } from "./WrapperDataLayer"

import { CheckIcon } from "../icons/CheckIcon"
import { EmptyIcon } from "../icons/EmptyIcon"

import * as styles from "./fields.module.scss"

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  value: boolean
  name: string
  id: string
  error?: string
  touched?: FormikTouched<{}>
}

export const AcceptmentField = ({ value, error, name, touched, ...props }: Props) => (
  <WrapperDataLayer touched={touched} error={error} name={name}>
    <div className={styles.acceptmentWrapper}>
      <div className={styles.acceptment}>
        <div>
          <Checkbox
            color="primary"
            checkedIcon={<CheckIcon />}
            icon={<EmptyIcon />}
            checked={value}
            className={styles.icon}
            {...props}
          />
        </div>
        <div>
          <FormHelperText>
            <span className={styles.acceptmentContent}>
              Выражаю{" "}
              <Link
                className={styles.acceptmentLink}
                href="https://app.sovcombank.ru/policy/"
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
              >
                согласие
              </Link>{" "}
              на&nbsp;обработку персональных данных и&nbsp;подтверждаю,&nbsp;что&nbsp; ознакомлен с
              <Link
                className={styles.acceptmentLink}
                href="https://sovcombank.ru/about/pages/policy-personal-data"
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
              >
                {" "}
                Политикой
              </Link>{" "}
              обработки персональных данных
            </span>
          </FormHelperText>
        </div>
      </div>
      <FormHelperText error classes={{ root: styles.acceptmentError }}>
        {error}
      </FormHelperText>
    </div>
  </WrapperDataLayer>
)
