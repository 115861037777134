import React, { useEffect } from "react"

import { graphql, PageProps } from "gatsby"
import Layout from "../../components/Layouts/mainPage"
import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { Form } from "../../components/Seo/Form"
import { SwitcherTextBlock } from "../../components/SwitcherTextBlock"
import { BannerWithAdvantages } from "../../components/Banners/BannerWithAdvantages"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { MoreBenefitsRedisign } from "../../components/MorBenefitsRedisgn"
import { BottomCta, RefinRedesign } from "../../components/BottomCta"
import { NewFooter } from "../../components/NewFooter"

import { PageData } from "../../interfaces/pageProps"
import { getPageData } from "../../helpers/getPageData"
import { NewHeader } from "../../components/Headers/NewHeader"
import RefinImgPayoff from "../../components/Banners/BannerWithAdvantages/Img/RefinImgPayoff"

const FORM_TITLE = "Оформите карту «Халва»"
const FORM_SUBTITLE = "Быстрая и бесплатная доставка или самовывоз"
const FORM_BTN_TEXT = "Оформить карту"
const HOW_INSTALLMENT_WORKS_TITLE = (
  <>
    Рефинансируйте
    <br />с Халвой
  </>
)
const MORE_BENEFITS_TITLE = (
  <>
    Преимущества
    <br />
    рефинансирования с Xалвой
  </>
)
const GIFT_TITLE = (
  <>
    Оформите карту «Халва»
    <br />
    и&nbsp;получите&nbsp;стикер&nbsp;PAY
    <br />
    <b>бесплатно</b>
  </>
)
const BANNER_TITLE = "Погасите кредитки"
const BANNER_DESCRIPTION = "в рассрочку на 24 месяца"

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter ligal={ligal} noIndex={noIndex}>
      <NewHeader
        redLogoWithSovcombank
        showHint={false}
        hasCTA
        additionalEventInDataLayer
        withoutHintAndCountDown
      />
      <BannerWithAdvantages
        variant="refinMedia"
        Img={RefinImgPayoff}
        title={BANNER_TITLE}
        description={BANNER_DESCRIPTION}
        orderNum="2"
        color="white"
        variantStyles="refinansirovanieKreditnoyKartyMedia"
      />
      <FourAdvantagesPoints variant="refin" orderNum="3" />
      <HowInstallmentWorks variant="refin" headTitle={HOW_INSTALLMENT_WORKS_TITLE} orderNum="4" />
      <Form
        variant="refin"
        noWoman
        title={FORM_TITLE}
        subtitle={FORM_SUBTITLE}
        formBtnText={FORM_BTN_TEXT}
        orderNum="5"
      />
      <MoreBenefitsRedisign
        variant="refin"
        bottomBlock={false}
        additionalEventInDataLayer
        title={MORE_BENEFITS_TITLE}
        hasSubtitle={false}
        orderNum="6"
      />
      <SwitcherTextBlock mode="refin" seoBlockText="" orderNum="7" />
      <BottomCta
        variant="refinRedesign"
        title={GIFT_TITLE}
        BgImg={RefinRedesign}
        orderNum="8"
        StickerPay
      />
      <NewFooter ligal={ligal} />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/halva/refinansirovanie-kreditnoy-karty-media/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
