import React from "react"

export const CheckIcon = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4 9.073a.8.8 0 0 1-.076 1.129l-6.366 5.57a.799.799 0 0 1-1.153-.035l-2.371-2.371a.8.8 0 0 1 1.13-1.132l1.87 1.87 5.837-5.106a.8.8 0 0 1 1.129.075Z"
      fill="#FF4E50"
    />
    <rect x=".8" y=".8" width="22.4" height="22.4" rx="5.2" stroke="#FF4E50" strokeWidth="1.6" />
  </svg>
)
